import React from "react";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default () => {
  if (typeof window !== "undefined") {
    const redirect = getParameterByName("redirect");
    if (redirect) {
      console.log(`Redirecting to ${redirect}...`);
      window.location = redirect;
    } else {
      console.log("No Redirect supplied, going home...");
      window.location = window.location.origin;
    }
  }

  return <div color="#fff">Loading Content...</div>;
};
